<script lang="ts" setup>
import {ref} from 'vue'
import {dialogHelper} from '@/helpers/dialog'
const props = defineProps<{
  small?: boolean
  isOpen: boolean
}>()
const emit = defineEmits<{
  (e: 'update:isOpen', value: boolean): void
}>()
const dialog = ref<HTMLDialogElement | null>(null)

const internalDialogOpen = ref(false)

watch(
  () => props.isOpen,
  isOpen => {
    if (isOpen) openModal()
    else closeModal()
  }
)

const openModal = () => {
  internalDialogOpen.value = true
  dialog.value && dialog.value.showModal()
}
const closeModal = () => dialog.value && !dialog.value.hasAttribute('inert') && dialog.value.close()

const close = () => {
  emit('update:isOpen', false)
  internalDialogOpen.value = false
}

onMounted(() => {
  if (!dialog.value) return
  dialogHelper(dialog.value)
  dialog.value.addEventListener('closed', close)

  if (props.isOpen) openModal()
})

onUnmounted(() => {
  if (!dialog.value) return
  if (props.isOpen) openModal()
  dialog.value.removeEventListener('closed', close)
})
</script>

<template>
  <Teleport to="body">
    <dialog ref="dialog" :modal-mode="props.small ? 'mini' : 'mega'" inert>
      <button class="reset" type="reset" @click="closeModal">
        <i class="fas fa-xmark"></i>
        <div class="sr-only">close</div>
      </button>
      <div class="slot-wrapper">
        <slot v-if="internalDialogOpen"></slot>
      </div>
    </dialog>
  </Teleport>
</template>

<style lang="postcss" scoped layer="">
@import 'open-props/media';
dialog {
  --dialog-max-height: calc(100vh - (var(--container-gap) * 4));
  display: block;
  color: var(--text-color-primary);
  max-inline-size: min(100dvw - (var(--container-gap) * 4), calc(var(--container-width) - var(--container-gap) * 2));
  height: fit-content;
  margin: auto;
  position: fixed;
  inset: 0;
  padding: 0;
  border-radius: var(--border-radius-medium);
  filter: var(--drop-shadow);
  z-index: 9999;
  overflow: hidden;
  transition: 0.5s;
  background: var(--background-color-secondary);
  border: 0;
  @media (--md-n-below) {
    max-inline-size: 95dvw;
  }
  @media (--motionOK) {
    animation: var(--animation-slide-out-down) forwards;
    animation-timing-function: var(--ease-squish-2);
  }

  &:not([open]) {
    pointer-events: none;
    opacity: 0;
  }

  &[modal-mode='mega'] {
    width: 100%;
    &::backdrop {
      background-color: var(--backdrop-color);
      opacity: 0.7;
    }
  }

  &[modal-mode='mini']::backdrop {
    backdrop-filter: none;
  }

  &::backdrop {
    transition: 0.5s ease;
  }

  &[loading] {
    visibility: hidden;
  }

  &[open] {
    @media (--motionOK) {
      animation: var(--animation-slide-in-up) forwards;
    }
  }
  & > button {
    position: absolute;
    right: 0;
    appearance: none;
    font-size: 2.5rem;
    padding: 1rem;
    z-index: 10;
    @media (--md-n-above) {
      visibility: hidden;
    }
  }
  .slot-wrapper {
    overflow: auto;
    max-height: calc(100vh - (var(--container-gap) * 2));
    padding: var(--container-gap);
    @media (--sm-n-below) {
      max-height: calc(100dvh - (var(--container-gap) * 2));
    }
  }
}
</style>
